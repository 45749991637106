.analytics-container {
  width: 100%;
  height: 100%;
  margin: 0rem 1rem 1rem 1rem;
}

.analytics-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#analytics-title {
  color: #5080f4;
  font-size: 1.5rem;
}

.period-table-row {
  border-bottom: 3px solid black;
}