body, html {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  line-height: 1.5;
  background-color: #F4F6F8;
  font-family:  'Roboto';
}

*, *::before, *::after {
  box-sizing: border-box;
}


a {
  text-decoration: none;
  color: unset;
}
