.page-views-widget {
  overflow-x: scroll;
}

.text-right {
  text-align: right;
}

.border {
  border: 1px solid #ccc;
  border-top: none;
}

.page-views-time-table {
  border: 1px solid black;
}

.table-heading {
  border-bottom: 1px solid #ccc;
}

.table-btn {
  padding: 5px 10px;
}
.page-views-time-table {
  margin: 1rem 0rem;
}

.page-view-time-row {
  border: 1px solid black;
}

/* Style the headers */
table thead th {
  background-color: #f0f0f0;
  font-weight: normal;
  padding: 5px 10px;
  border: 1px solid #ccc;
  min-width: 9rem;
}

/* Style the table rows */
table tbody tr {
  border: 1px solid #ccc;
}
