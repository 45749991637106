.nameContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  gap: 14px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 16px;
  }
}

.countryInputsContainer {
  flex: 1;
}
