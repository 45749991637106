.containerWrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  background-color: #f4f6f8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  max-width: 1376px;
  gap: 24px;
}

.welcomeContainer {
  max-width: 766px;
  border-radius: 15px;
  background-color: #142a5e;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 38px 30px;
}

.pageHeader {
  display: none;
}

.headerLogo {
  width: auto;
  height: 26px;
}

.logo {
  width: 106px;
  height: 58px;
  object-fit: contain;
}

.registrationDisplay {
  max-width: 680px;
  height: auto;
}

.imagesContainer {
  display: flex;
  gap: 39px;
  margin-top: 3rem;
  justify-content: center;
}

.title {
  font-family: Inter;
  font-size: 40px;
  color: white;
  font-weight: 600;
  margin: 0;
  margin-bottom: 16px;
  padding: 0 29px;
  text-align: center;
}

.subtitle {
  color: white;
  font-family: Inter;
  font-size: 18px;
  font-weight: 300;
  margin: 0;
  margin-bottom: 30px;
  padding: 0 29px;
  text-align: center;
}

.content {
  max-width: 480px;
  max-height: 872px;
  background-color: white;
  border-radius: 12px;
  border: 0.5px solid #878787;
  box-shadow: 0px 4px 64px 0px rgba(0, 0, 0, 0.05);
}

@media (max-width: 900px) {
  .containerWrapper {
    align-items: unset;
  }
  .pageHeader {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 18px;
    gap: 16px;
  }
  .container {
    justify-content: center;
    margin-top: 0;
  }
  .welcomeContainer {
    display: none;
  }
  .content {
    min-width: 0;
    width: 94%;
    max-height: unset;
  }
}
