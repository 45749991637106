.container {
  height: 60px;
  width: 100%;
  border-radius: 12px;
  border: 1px solid #d0d5dd;
  background: #fff;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0 11px;

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}

.input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  color: black;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding: 0;
}

.input::placeholder {
  color: #999;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.iconContainer {
  height: 100%;
  padding: 0 11px;
  display: flex;
  justify-content: center;
  align-items: center;
}
