.spinnerLoader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  background: linear-gradient(0deg, rgb(171 203 209) 33%, rgb(57 156 173) 100%);
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.spinnerLoader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background: #f4f6f8;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinnerLoader.register {
  width: 35px;
  height: 35px;
  border-top: 4px solid rgb(57 156 173);
  border-right: 4px solid #fff;
  border-bottom: 4px solid #fff;
  border-left: 4px solid #fff;
}
.spinnerLoader.fullscreen {
  width: 70px;
  height: 70px;
}
