.container {
  aspect-ratio: 505 / 563;
  padding: 43px 47px 17px 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  color: #142a5e;
  font-family: Inter;
  font-size: 31px;
  font-weight: 500;
  line-height: normal;
  font-style: normal;
  margin: 0;
}

.subtitle {
  color: #4e6276;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  font-style: normal;
  margin: 10px 0 0 0;
}

.alreadyText {
  color: #7d7d7d;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 26px 0;
}

.signInText {
  color: #142a5e;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
}

footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 768px) {
  .container {
    aspect-ratio: unset;
    gap: 32px;
  }
  header {
    .title {
      font-size: 24px;
    }
  }

  footer {
    .alreadyText {
      font-size: 12px;
    }

    .signInText {
      font-size: 12px;
    }
  }
}
